<template>
  <div style="height: 100%; padding: 56px 0px;">
    <mobile-group-header
      label="토너재고(개)"
      description="토너 재고를 입력하고 저장하면 이력이 쌓입니다."
    />
    <mobile-input
      v-model="editContext.blackTonerCurCount"
      label="블랙"
      type="number"
    />
    <mobile-input
      v-model="editContext.cyanTonerCurCount"
      label="사이안"
      type="number"
    />
    <mobile-input
      v-model="editContext.magentaTonerCurCount"
      label="마젠타"
      type="number"
    />
    <mobile-input
      v-model="editContext.yellowTonerCurCount"
      label="옐로우"
      type="number"
    />
    <mobile-group-header
      label="자동화 설정"
    />
    <mobile-input
      v-model="editContext.refillTonerChangingLevel"
      label="재고 자동 차감 임계치"
      description="해당 수치만큼 토너 잔량이 증가하면 재고가 차감됩니다."
      type="number"
      unit="%"
    />
    <mobile-select
      v-model="editContext.alertChangedByMonitoring"
      label="재고 자동 차감 알림"
      :items="selectItems.ynItem"
      description="토너재고가 자동으로 차감되면 알림을 받습니다."
    />
    <mobile-select
      v-model="editContext.alertTonerCountYn"
      :items="selectItems.ynItem"
      label="재고 부족 알림 설정"
      description="재고가 자동차감으로 해당 설정 이하로 떨어지면 알림을 받습니다."
    />
    <mobile-input
      v-if="editContext.alertTonerCountYn === 'Y'"
      v-model="editContext.alertTonerCount"
      type="number"
      unit="개"
      label="재고 부족 알림 개수 설정"
      description="재고 부족 알림 받을 개수를 설정합니다."
    />
    <mobile-input
      v-model="editContext.collectedDelayDays"
      label="지연 수집일자 임계치"
      type="number"
      unit="일"
      description="모니터링 정보가 수집된 시점과 직전 수집 시점의 간격이 설정된 일수보다 크면 자동차감을 하지 않습니다."
    />
    <mobile-input
      v-model="editContext.remark"
      label="비고"
      type="text-area"
    />
  </div>
</template>

<script>
import {MAXLENGTH, PATH} from "@/constants";
import {cloneDeep} from "lodash";

export default {
  name: "PrinterTonerManagementEditComp",
  components: {},
  props: {
    value: {
      type: Object,
      default: undefined,
    },
    oriData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      loading: false,
      editContext: undefined,
      selectItems: {
        ynItem: [
          {label: 'Y', value: 'Y', key: 'type-1'},
          {label: 'N', value: 'N', key: 'type-2'},
        ],

      },
    }
  },
  computed: {},
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.editContext = newValue
      }
    },
    editContext: {
      deep: true,
      handler(newValue) {
        this.$emit("input", newValue);
      }
    },
  },

  mounted() {
    //this.init()
  },

  methods: {
  },
}
</script>

<style scoped>

.top_info_switch_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.top_info_usage {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.top_info_usage .usage_chip {
  background-color: #D5EAFA;
  color: #0c98fe;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  margin-right: 8px;
  min-width: 88px;
}

.top_info_usage div:not(.usage_chip) {
  margin-right: 6px;
}

table {
  width: 100%;
  line-height: 34px;
  border-spacing: 0;
}

table tr th {
  color: #333;
}

table tr td {
  border-bottom: 1px solid #ddd;
  color: #333;
}

>>> .usage .v-input--selection-controls__input > .v-input--switch__track {
  width: 82px !important;
}

>>> .usage .v-input--selection-controls__input {
  width: 77px !important;
}

>>> .usage .v-input--switch__thumb {
  left: 34px !important;
  background-color: white !important;
}

>>> .cumulative_usage .v-input--selection-controls__input > .v-input--switch__track {
  width: 112px !important;
}

>>> .cumulative_usage .v-input--selection-controls__input {
  width: 103px !important;
  position: relative;
}

>>> .cumulative_usage .v-input--switch__thumb {
  left: 2px !important;
  background-color: white !important;
}

.switch {
  font-size: 0.875rem;
  line-height: 22px;
}

>>> .false_text {
  position: absolute;
  right: 11px;
  top: 4px;
  pointer-events: none;
}

>>> .v-card__text {
  padding: 0px 10px 10px;
}

>>> .v-input--switch--inset .v-input--switch__track {
  opacity: 1;
}
</style>
