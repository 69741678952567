<template>
  <v-card
    class="mx-0"
    width="100%"
    flat
    style="border-radius: 0px;"
  >
    <!--    :color="{'red': item.isReservationIsOver }"-->
    <v-list-item
      v-ripple="false"
      class="text-left boder-bottom"
      style="padding: 14px 18px !important;"
      @click="onDetailClicked(item)"
    >
      <v-list-item-content class="pa-0">
        <v-list-item-title
          class="d-flex justify-space-between align-start"
        >
          <div
            v-if="item.raw.printerStateCode !== '01'"
            class="font__16 color__blue"
            style="line-height: 25px;"
          >
            <span>{{ t(item.raw.printerStateCodeName) || '' }}</span>
          </div>

          <v-spacer />

          <div
            class="font__16 color__blue"
            style="line-height: 25px; padding-top: 3px; margin-left: 5px;"
          >
            <span :style="{color:(item.isThresholdExceeded ? 'red':'black')}">{{ item.formattedCollectedDate }}</span>
          </div>
        </v-list-item-title>

        <v-list-item-title
          class="d-flex justify-space-between align-start"
        >
          <div
            class="black--text font__17 font-weight-bold"
            style="white-space: pre-wrap !important;"
          >
            <span v-if="!!item.raw.accountId">{{ item.raw.accountName }} [{{ item.raw.accountId }}]</span>
            <span v-else>{{ '본사' | translate }}</span>
          </div>
        </v-list-item-title>

        <v-list-item-subtitle v-if="!!item.raw.accountBranchId">
          {{ item.raw.accountBranchName }}
        </v-list-item-subtitle>

        <v-list-item-subtitle class="d-flex justify-space-between subtitle-2">
          <div
            class="d-flex flex-column justify-center font__14"
          >
            <span class="black--text">{{ item.raw.printerModelName }}</span>
            <span class="color__666">{{ item.raw.printerSerialNo }}</span>
          </div>
          <div
            class="d-flex flex-column justify-space-between align-end"
            style="flex: 0 100px"
          />
        </v-list-item-subtitle>


        <div
          style="display: flex; flex-direction: row; flex-basis: 100%; margin-top: 10px;"
          class="font__14"
        >
          <div style="display: flex; flex-direction: column; flex: 1 0 0;">
            <span class="font-weight-bold">
              {{ '잉크/토너' | translate }}
            </span>
            <div style="display: flex; flex-direction: row; width: 100px;">
              <ink-display
                :percent="unlimited(item.raw) ? 50 : item.raw.black"
                black
              />
              <ink-display
                :percent="unlimited(item.raw) ? 50 : item.raw.cyan"
                cyan
              />
              <ink-display
                :percent="unlimited(item.raw) ? 50 : item.raw.magenta"
                magenta
              />
              <ink-display
                :percent="unlimited(item.raw) ? 50 : item.raw.yellow"
                yellow
              />
            </div>
            <div style="display: flex; flex-direction: row; margin-top: 10px;">
              <span
                class="font-weight-bold"
                style="flex: 1 0 0;"
              >
                {{ '컬러' | translate }}
              </span>
              <span
                class="font-weight-bold"
                style="flex: 1 0 0;"
              >
                {{ '흑백' | translate }}
              </span>
            </div>
            <div style="display: flex; flex-direction: row;">
              <span style="flex: 1 0 0; font-size: 12px;">
                {{ displayAmount(calcColorPageCount) }}
              </span>
              <span style="flex: 1 0 0; font-size: 12px;">
                {{ displayAmount(calcMonoPageCount) }}
              </span>
            </div>
            <div style="display: flex; flex-direction: row; margin-top: 10px;">
              <span class="font-weight-bold">
                {{ '무한잉크' | translate }}
              </span>
              <span style="margin-left: 10px; font-size: 12px;"> {{ item.raw.unlimitedInkYn }}</span>
            </div>
          </div>
          <div style="display: flex; flex-direction: column; flex: 1 0 0;">
            <span
              class="font-weight-bold"
            >
              {{ '드럼' | translate }}
            </span>

            <div style="display: flex; flex-direction: row; width: 100px;">
              <ink-display
                :percent="unlimited(item.raw) ? 0 : item.raw.blackDr"
                black
              />
              <ink-display
                :percent="unlimited(item.raw)? 0 : item.raw.cyanDr"
                cyan
              />
              <ink-display
                :percent="unlimited(item.raw)? 0 : item.raw.magentaDr"
                magenta
              />
              <ink-display
                :percent="unlimited(item.raw)? 0 : item.raw.yellowDr"
                yellow
              />
            </div>
            <div style="display: flex; flex-direction: row; margin-top: 10px;">
              <span
                class="font-weight-bold"
                style="flex: 1 0 0;"
              >
                {{ '합계' | translate }}
              </span>
              <span
                class="font-weight-bold"
                style="flex: 1 0 0;"
              >
                {{ '엔진합계' | translate }}
              </span>
            </div>
            <div style="display: flex; flex-direction: row;">
              <span style="flex: 1 0 0; font-size: 12px;">
                {{ displayAmount(calcTotalPageCount) }}
              </span>
              <span style="flex: 1 0 0; font-size: 12px;">
                {{ displayAmount(item.raw.engineCount) }}
              </span>
            </div>

            <div style="display: flex; flex-direction: row; margin-top: 10px;">
              <span
                class="font-weight-bold"
              >
                {{ '설치위치'| translate }}
              </span>
              <span style="width: 75px; margin-left: 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 12px;">
                {{ item.raw.installLocation ? item.raw.installLocation : '-' }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex align-center">
          <div
            v-if="tonerRefill"
            style="display: flex; margin-top: 10px;  flex-direction: column;"
          >
            <span
              class="font-weight-bold font__14"
              style="flex: 1 0 0;"
            >
              {{ '토너재고' | translate }}
            </span>
            <div style="display: flex; flex-direction: row; width: 100px;">
              <ink-display
                :percent="tonerRefill.blackTonerCurCount"
                :is-toner="true"
                black
              />
              <ink-display
                :percent="tonerRefill.cyanTonerCurCount"
                :is-toner="true"
                cyan
              />
              <ink-display
                :percent="tonerRefill.magentaTonerCurCount"
                :is-toner="true"
                magenta
              />
              <ink-display
                :percent="tonerRefill.yellowTonerCurCount"
                :is-toner="true"
                yellow
              />
            </div>
          </div>
          <v-list-item-subtitle
            class="flex"
            style="margin-top: 10px;"
          >
            <v-spacer />
            <span>{{ "설치" | translate }}</span>
            <span class="ml-2">{{ item.raw.regDatetime | datetime }}</span>
            <context-menu
              :printer="item.raw"
              :show-map-bt="false"
              :show-printer-bt="true"
            />
          </v-list-item-subtitle>
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
    import VuexProps from "@/framework/vuex/VuexProps";
    import InkDisplay from "@/components/part/printer/InkDisplay";
    import contextMenu from "@/components/widgets/ContextMenu";
    import {PATH} from "@/constants";

    export default {
        name: "PrinterItemCard",
        components: {InkDisplay,contextMenu},
        mixins: [VuexProps],
        props: {
            item: {type: Object, default: undefined},
            options: {
                type: Object, default: () => {
                }
            },
        },
        data() {
          return {
            tonerRefill: {},
          }

        },
        computed: {
          calcMonoPageCount() {
            const a3Double = this.item.raw.a3DoubleCountYn === 'Y' ? 2 : 1
            return Number(this.item.raw.finalMonoPageCount4) + (Number(this.item.raw.finalMonoPageCount3) * a3Double)
          },

          calcColorPageCount() {
            const a3Double = this.item.raw.a3DoubleCountYn === 'Y' ? 2 : 1
            return Number(this.item.raw.finalColorPageCount4) + (Number(this.item.raw.finalColorPageCount3) * a3Double)
          },

          calcTotalPageCount() {
            const a3Double = this.item.raw.a3DoubleCountYn === 'Y' ? 2 : 1
            return Number(this.item.raw.finalPageCount4) + Number(this.item.raw.finalPageCount3) * a3Double
          },

        },
        created() {
          this.loadTonerRefill()
        },
      methods: {
            /**
             * 상세 버튼 클릭시
             * @param work
             */
            onDetailClicked(item) {
                this.routerManager.push({
                    name: "PrinterDetail",
                    params: {
                        printerContractId: item.raw.printerContractId || 0,
                        monitoringPrinterId: item.raw.monitoringPrinterId || 0,
                    }
                });
            },
          displayAmount(n){
              return Number(n).toLocaleString();
          },
          unlimited(item) {
            return item.unlimitedInkYn === 'Y'
          },



          /**
           * 토너 재고 관리 데이터 조회
           */
          async loadTonerRefill() {
            if (
                this.item.raw.printerModelType !== '1' ||
                !this.item.raw.refillHistory ||
                this.item.raw.refillHistory === 0
            ){
              this.tonerRefill = null
              return
            }
            const result = await this.$request(PATH.TONER_REFILL.RETRIEVE)
                .setObject({
                  printerTonerRefillId: this.item.raw.refillHistory,
                })
                .enqueue()
            this.tonerRefill = result
          },

        }
    }
</script>
<style scoped>



    .font__05rem {
        font-size: 0.5rem !important;
        line-height: 1.375rem !important;
        font-weight: normal !important;
    }

    .M_circle2 {
        border-radius: 50%;
        width: 16px;
        height: 16px;
        line-height: 16px;
        display: inline-block;
        color: #fff;
        text-align: center;
        font-size: 10px;
        background: #999;
    }

    .C_circle2 {
        border-radius: 50%;
        width: 16px;
        height: 16px;
        line-height: 16px;
        display: inline-block;
        color: #fff;
        text-align: center;
        font-size: 10px;
        background: #00bcd4;
        margin-top: 5px;
    }


</style>
