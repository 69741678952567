<template>
  <div style="height: 100%; padding: 56px 0 70px;">
    <div
      v-if="!loading"
      style="height: 100%; overflow: auto;"
    >
      <mobile-group-header
        label="토너재고(개)"
      />
      <m-form
        ref="form"
        :edit.sync="editMode"
        :form-data.sync="formData"
        :form-info="formInfo"
        class="overflow-y-auto"
        form-provider="mps"
      />
      <mobile-group-header
        label="자동화 설정"
      />
      <m-form
        ref="form"
        :edit.sync="editMode"
        :form-data.sync="formData"
        :form-info="formInfo2"
        class="overflow-y-auto"
        form-provider="mps"
      />
      <mobile-group-header
        label="기타 정보"
      />
      <m-form
        ref="form"
        :form-data.sync="formData"
        :form-info="etcInfo"
        class="overflow-y-auto"
        form-provider="mps"
      />
    </div>
    <template v-if="loading">
      <progress-loading />
    </template>
    <div>
      <additional-information-view
        :expand="expanded"
      >
        <template #header>
          <additional-information-view-header :expand.sync="expanded">
            <printer-toner-history-header :expanded="expanded" />
          </additional-information-view-header>
        </template>
        <template #content>
          <additional-information-view-content :expand="expanded">
            <div style="height: 100%;overflow-y: auto;">
              <printer-toner-history-content
                :expanded="expanded"
                :printer="printer"
                :toner-refill-hist="tonerRefillHist"
                :is-end="isEnd"
                @add-items="addItems"
              />
            </div>
          </additional-information-view-content>
        </template>
      </additional-information-view>
    </div>
  </div>
</template>

<script>
import {PATH} from "@/constants";
import AdditionalInformationViewContent from "@/components/widgets/additional/AdditionalInformationViewContent";
import additionalInformationView from "@/components/widgets/additional/AdditionalInformationView";
import AdditionalInformationViewHeader from "@/components/widgets/additional/AdditionalInformationViewHeader";
import PrinterTonerHistoryHeader from "@/components/windows/printer/PrinterTonerHistoryHeader";
import PrinterTonerHistoryContent from "@/components/windows/printer/PrinterTonerHistoryContent";
import MForm from "@/assets/plugins/mps-form/MForm";
import progressLoading from "@/components/part/ProgressLoading";
export default {
    name: "PrinterTonerManagementComp",
    components: {
      MForm,
      AdditionalInformationViewContent,
      additionalInformationView,
      AdditionalInformationViewHeader,
      PrinterTonerHistoryHeader,
      PrinterTonerHistoryContent,
      progressLoading,
    },
    props: {
        printer: undefined,
        searchOptions: undefined,
        identifier: undefined,
        editMode: undefined,
        formData: undefined,
        tonerRefillHist: {
          type: Array,
          default: () => []
        },
        isEnd: undefined,
        loading: {type: Boolean, default: true},
    },
    data() {
        return {
          expanded: false,
          formInfo: [
            {
              mutable: false,
              title: this.$translate('블랙'),
              key: 'blackTonerCurCount',
              formReadType: this.$mps.form.types.TEXTAREA,
              formWriteType: this.$mps.form.types.TEXTAREA,
            },
            {
              mutable: true,
              title: this.$translate('사이안'),
              key: 'cyanTonerCurCount',
              formReadType: this.$mps.form.types.TEXTAREA,
              formWriteType: this.$mps.form.types.TEXTAREA,
            },
            {
              mutable: false,
              title: this.$translate('마젠타'),
              key: 'magentaTonerCurCount',
              formReadType: this.$mps.form.types.TEXTAREA,
              formWriteType: this.$mps.form.types.TEXTAREA,
            },
            {
              mutable: false,
              title: this.$translate('옐로우'),
              key: 'yellowTonerCurCount',
              formReadType: this.$mps.form.types.TEXTAREA,
              formWriteType: this.$mps.form.types.TEXTAREA,
            },
          ],
          formInfo2: [
            {
              mutable: true,
              title: this.$translate('재고 자동 차감 임계치'),
              key: 'refillTonerChangingLevel',
              read: (inEditMode, data) => { return data.refillTonerChangingLevel + this.$translate('%')
              },
              formReadType: this.$mps.form.types.TEXTAREA,
              formWriteType: this.$mps.form.types.TEXTAREA,
            },
            {
              mutable: true,
              title: this.$translate('재고 자동 차감 알림'),
              key: 'alertChangedByMonitoring',
              read: (inEditMode, data) => {
                return {
                  codeKey: data.alertChangedByMonitoring === "Y" ? "Y" : "N",
                  codeValue: data.alertChangedByMonitoring === "Y" ? "Y" : "N",
                }
              },
              write: (data, v) => {
                if (!v) return;
                data.alertChangedByMonitoring = v.codeKey;
              },
              formReadType: this.$mps.form.types.OPTIONS_PICKER,
              formWriteType: this.$mps.form.types.OPTIONS_PICKER,
              options: {
                items: [
                  {codeKey: "Y", codeValue: "Y"},
                  {codeKey: "N", codeValue: "N"},
                ]
              },
            },
            {
              mutable: false,
              title: this.$translate('재고 부족 알림 설정'),
              key: 'alertTonerCountYn',
              read: (inEditMode, data) => data.alertTonerCountYn === "Y" ? data.alertTonerCount + this.$translate('개 이하') : this.$translate('받지 않음'),
              formReadType: this.$mps.form.types.TEXTAREA,
              formWriteType: this.$mps.form.types.TEXTAREA,
            },
            {
              mutable: false,
              title: this.$translate('지연 수집일자 임계치'),
              key: 'collectedDelayDays',
              read: (inEditMode, data) => { return data.collectedDelayDays + this.$translate('일')
              },
              formReadType: this.$mps.form.types.TEXTAREA,
              formWriteType: this.$mps.form.types.TEXTAREA,
            },
          ],
          etcInfo: [
            {
              mutable: false,
              title: this.$translate('등록자'),
              key: 'registrantName',
              read: (inEditMode, data) => { return data.registrantName},
              formReadType: this.$mps.form.types.TEXTAREA,
              formWriteType: this.$mps.form.types.TEXTAREA,
            },
            {
              mutable: false,
              title: this.$translate('등록일시'),
              key: 'regDate',
              read: (inEditMode, data) => { return this.$dateTime(data.regDate)},
              formReadType: this.$mps.form.types.TEXTAREA,
              formWriteType: this.$mps.form.types.TEXTAREA,
            },
            {
              mutable: false,
              title: this.$translate('수정자'),
              key: 'modifierName',
              read: (inEditMode, data) => { return data.modifierName },
              formReadType: this.$mps.form.types.TEXTAREA,
              formWriteType: this.$mps.form.types.TEXTAREA,
            },
            {
              mutable: false,
              title: this.$translate('수정일시'),
              key: 'uptDate',
              read: (inEditMode, data) => { return this.$dateTime(data.uptDate)},
              formReadType: this.$mps.form.types.TEXTAREA,
              formWriteType: this.$mps.form.types.TEXTAREA,
            },
            {
              mutable: false,
              title: this.$translate('비고'),
              key: 'remark',
              read: (inEditMode, data) => { return data.remark},
              formReadType: this.$mps.form.types.TEXTAREA,
              formWriteType: this.$mps.form.types.TEXTAREA,
            },
          ],

        }
    },
    computed: {

    },
    watch: {
    },
    mounted() {
    },
    unmounted() {
      this.expanded = false
    },
    methods: {
        addItems(cb){
          this.$emit('add-items', cb);
        },
    },
}
</script>

<style scoped>
    >>> .v-tabs {
        display: none;
    }

    .v-tabs-items {
        height: 100%;
        overflow-y: auto;
    }

    .v-card__text {
        padding: 14px 0px;
    }

    .top_info_switch_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
    }

    .top_info_usage {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
    }

    .top_info_usage .usage_chip {
        background-color: #D5EAFA;
        color: #0c98fe;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 6px;
        margin-right: 8px;
        min-width: 88px;
    }

    .top_info_usage div:not(.usage_chip) {
        margin-right: 6px;
    }

    table {
        width: 100%;
        line-height: 34px;
        border-spacing: 0;
    }

    table tr th {
        color: #333;
    }

    table tr td {
        border-bottom: 1px solid #ddd;
        color: #333;
    }

    >>> .usage .v-input--selection-controls__input > .v-input--switch__track {
        width: 82px !important;
    }

    >>> .usage .v-input--selection-controls__input {
        width: 77px !important;
    }

    >>> .usage .v-input--switch__thumb {
        left: 34px !important;
        background-color: white !important;
    }

    >>> .cumulative_usage .v-input--selection-controls__input > .v-input--switch__track {
        width: 112px !important;
    }

    >>> .cumulative_usage .v-input--selection-controls__input {
        width: 103px !important;
        position: relative;
    }

    >>> .cumulative_usage .v-input--switch__thumb {
        left: 2px !important;
        background-color: white !important;
    }

    .switch {
        font-size: 0.875rem;
        line-height: 22px;
    }

    >>> .false_text {
        position: absolute;
        right: 11px;
        top: 4px;
        pointer-events: none;
    }

    >>> .v-card__text {
        padding: 0px 10px 10px;
    }

    >>> .v-input--switch--inset .v-input--switch__track {
        opacity: 1;
    }
</style>
