<template>
  <div
    id="printer_daily_wrap_con"
    class="overflow-y-auto overflow-x-hidden"
  >
    <my-tool-bar
      :title="title"
    >
      <template #buttons>
        <toolbar-save-action
          @save="onSave"
          @refresh="onRefresh"
        />
      </template>
    </my-tool-bar>
    <div style="position: relative; top: 56px;" />
    <printer-toner-management-edit-comp
      v-if="!!context"
      ref="editComp"
      v-model="context"
      :ori-data="tonerRefill"
    />
  </div>
</template>

<script>
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import myToolBar from "@/components/app/toolbar/MyToolBar";
import FilterButtonMixin from "@/components/app/toolbar/component/filter-button/FilterButtonMixin";
import BackGuardEditModeInterface from "@/components/windows/mixins/BackGuardEditModeInterface";
import PrinterTonerManagementEditComp from "@/components/windows/printer/PrinterTonerManagementEditComp";
import {PATH} from "@/constants";
import {cloneDeep} from "lodash";
import toolbarSaveAction from "@/components/app/toolbar/ToolbarSaveAction";

export default {
  name: "PrinterTonerManagementCreate",
  components: {
    PrinterTonerManagementEditComp,
    myToolBar,
    toolbarSaveAction

  },
  extends: WindowComponent,
  mixins: [FilterButtonMixin,BackGuardEditModeInterface],
  props: {
    printer: undefined,
  },

  data() {
      return {
        loading: false,
        loadingUpdate: false,
        identifier:0,
        actionMode: false,
        tonerRefill: undefined,
        context: undefined,
      }
  },

  watch: {
    tonerRefill: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.context = cloneDeep(newVal)
      }
    }
  },

  mounted() {
    this.scrollToTop()
    this.init()
  },

  methods: {

    init() {
      this.initTonerRefill()
    },

    scrollToTop() {
      window.document.getElementById('printer_daily_wrap_con').scrollTo(0,0)
    },

    async onSave() {
      if (this.loading) return
      this.loading = true
      try {
        const result = await this.$request(PATH.TONER_REFILL.INSERT_OR_UPDATE)
            .setObject(this.context)
            .enqueue()
        if (result) {
          this.$snackbarSuccess(this.$translate("등록 되었습니다."));
          await this.loadDetail()
          this.routerManager.replace({name: "PrinterTonerManagementDetail", params: {
              monitoringPrinterId: this.printer.monitoringPrinterId,
              refillHistory: this.printer.refillHistory,
              printerContractId: this.printer.printerContractId,
            }});
        } else {
          this.$snackbarError(this.t('등록 중 에러가 발생하였습니다.'))
        }
      } catch (e) {
        this.loading = false
      }
      this.loading = false

    },


    /**
     * 토너재고 데이터 init
     * data가 없을 경우 최초 등록으로 판단 한다.
     */
    initTonerRefill(data) {
      this.tonerRefill = {
        printerTonerRefillId: null,
        // 현재 재고
        blackTonerCurCount: 0,
        cyanTonerCurCount: 0,
        magentaTonerCurCount: 0,
        yellowTonerCurCount: 0,
        // 이전 재고
        blackTonerPrevCount: 0,
        cyanTonerPrevCount:  0,
        magentaTonerPrevCount: 0,
        yellowTonerPrevCount: 0,
        // 현재 잔량
        blackTonerCur: this.printer.black,
        cyanTonerCur: this.printer.cyan,
        magentaTonerCur: this.printer.magenta,
        yellowTonerCur: this.printer.yellow,
        // 이전 잔량
        blackTonerPrev: this.printer.black,
        cyanTonerPrev: this.printer.cyan,
        magentaTonerPrev: this.printer.magenta,
        yellowTonerPrev:  this.printer.yellow,
        // 등록 수정
        regDate: this.$now().toISO(),
        uptDate: this.$now().toISO(),
        registrant:  this.$userManager.user.userId,
        registrantName: undefined,
        modifier: this.$userManager.user.userId,
        modifierName: undefined,
        // 기본정보
        rentalCompanyId: null,
        accountId: this.printer.accountId || 0,
        printerSerialNo: this.printer.printerSerialNo,
        printerMacAddress: this.printer.printerMacAddress,
        deleteYn: 'N',
        // 재고 관련 설정
        refillTonerChangingLevel: 80,
        alertChangedByMonitoring: 'N',
        alertTonerCountYn: 'N',
        alertTonerCount:  0,
        collectedDelayDays: 20,
        // 모니터링 프린터 정보
        monitoringPrinterId: this.printer.monitoringPrinterId,
      }
    },

    onRefresh() {
      this.$dialog()
          .message(this.$translate('편집중인 정보를 초기화합니다.'))
          .buttonNegative(this.$translate('취소'))
          .buttonPositive(this.$translate('초기화'), () => {
            this.$refs.editComp.refresh()
          })
          .build().show();
    },

    async loadDetail(loading = true) {
      if (!this.printer.monitoringPrinterId) return;
      this.loading = loading;
      try {
        const response = await this.$request(PATH.PRINTER.GET)
            .setObject({
              monitoringPrinterId: this.printer.monitoringPrinterId,
            })
            .wait(500)
            .enqueue()
        this.printer = response.data
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },




  }
}
</script>

<style scoped>
</style>
