<template>
  <div
    id="printer_daily_wrap_con"
    class="overflow-y-auto overflow-x-hidden"
  >
    <my-tool-bar
      :title="title"
      :action-mode="actionMode"
      :searchable="true"
    >
      <template #action>
        <toolbar-modify-action
          @close="setEditMode(false)"
          @save="onSave"
        />
      </template>
      <template #buttons>
        <v-btn
          icon
          @click="onModifyModeClicked"
        >
          <img src="@/assets/images/main/svg/button_Edit.svg">
        </v-btn>
      </template>
    </my-tool-bar>
    <div style="position: relative; top: 56px;" />
    <template v-if="!loading">
      <printer-toner-management-comp
        v-if="!editMode"
        ref="form"
        :printer="printer"
        :edit-mode="editMode"
        :form-data="tonerRefill"
        :toner-refill-hist="tonerRefillHist"
        :is-end="isEnd"
        :loading="loading"
        @add-items="addItems"
      />
      <printer-toner-management-edit-comp
        v-if="editMode && !!context"
        ref="form"
        v-model="context"
        :ori-data="tonerRefill"
      />
    </template>
  </div>
</template>

<script>
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import myToolBar from "@/components/app/toolbar/MyToolBar";
import FilterButtonMixin from "@/components/app/toolbar/component/filter-button/FilterButtonMixin";
import printerTonerManagementComp from "@/components/windows/printer/PrinterTonerManagementComp";
import BackGuardEditModeInterface from "@/components/windows/mixins/BackGuardEditModeInterface";
import toolbarModifyAction from "@/components/app/toolbar/ToolbarModifyAction";
import PrinterTonerManagementEditComp from "@/components/windows/printer/PrinterTonerManagementEditComp";
import {PATH} from "@/constants";
import {cloneDeep} from "lodash";

export default {
  name: "PrinterTonerManagementDetail",
  components: {
    PrinterTonerManagementEditComp,
    myToolBar,
    printerTonerManagementComp,
    toolbarModifyAction,

  },
  extends: WindowComponent,
  mixins: [FilterButtonMixin, BackGuardEditModeInterface],
  props: {
    monitoringPrinterId: undefined,
    refillHistory: undefined,
    printerContractId: undefined,
  },

  data() {
    return {
      loading: false,
      loadingUpdate: false,
      identifier: 0,
      editMode: false,
      actionMode: false,
      tonerRefill: undefined,
      tonerRefillHist: [],
      context: undefined,
      printer: undefined,
      historySearchParam: {
        pageSize: 20,
        pageNumber: 1,
        maxPage: 0,
      },
      isEnd: false,
    }
  },

  watch: {
    tonerRefill: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.context = cloneDeep(newVal)
      }
    }
  },

  mounted() {
    this.scrollToTop()
    this.init()
  },

  methods: {

    async init() {
      this.editMode = false
      this.actionMode = false
      this.historySearchParam.pageNumber = 1
      this.printer = undefined
      this.tonerRefill = undefined
      this.tonerRefillHist.splice(0, this.tonerRefillHist.length)
      this.isEnd = true
      await this.loadDetail()
      await this.loadTonerRefill()
    },

    scrollToTop() {
      window.document.getElementById('printer_daily_wrap_con').scrollTo(0, 0)
    },

    onModifyModeClicked() {
      this.setEditMode(true)
    },

    async onSave() {
      if (this.loading) return
      this.loading = true
      try {
        const result = await this.$request(PATH.TONER_REFILL.INSERT_OR_UPDATE)
          .setObject(this.context)
          .enqueue()
        if (result) {
          this.$snackbarSuccess(this.$translate("등록 되었습니다."));
          this.setEditMode(false)
          this.init()
        } else {
          this.$snackbarError(this.t('등록 중 에러가 발생하였습니다.'))
        }
      } catch (e) {
        this.loading = false
      }
      this.loading = false

    },
    setEditMode(enabled) {
      if(!enabled) this.context = this.tonerRefill
      this.actionMode = enabled;
      this.editMode = enabled;
    },

    /**
     * 토너재고 데이터 init
     * data가 없을 경우 최초 등록으로 판단 한다.
     */
    initTonerRefill(data) {
      this.tonerRefill = {
        printerTonerRefillId: data ? data.printerTonerRefillId : null,
        // 현재 재고
        blackTonerCurCount: data ? data.blackTonerCurCount : 0,
        cyanTonerCurCount: data ? data.cyanTonerCurCount : 0,
        magentaTonerCurCount: data ? data.magentaTonerCurCount : 0,
        yellowTonerCurCount: data ? data.yellowTonerCurCount : 0,
        // 이전 재고
        blackTonerPrevCount: data ? data.blackTonerCurCount : 0,
        cyanTonerPrevCount: data ? data.cyanTonerCurCount : 0,
        magentaTonerPrevCount: data ? data.magentaTonerCurCount : 0,
        yellowTonerPrevCount: data ? data.yellowTonerCurCount : 0,
        // 현재 잔량
        blackTonerCur: this.printer.black,
        cyanTonerCur: this.printer.cyan,
        magentaTonerCur: this.printer.magenta,
        yellowTonerCur: this.printer.yellow,
        // 이전 잔량
        blackTonerPrev: data
          ? data.blackTonerCur
          : this.printer.black,

        cyanTonerPrev: data ? data.cyanTonerCur : this.printer.cyan,
        magentaTonerPrev: data
          ? data.magentaTonerCur
          : this.printer.magenta,

        yellowTonerPrev: data
          ? data.yellowTonerCur
          : this.printer.yellow,

        // 등록 수정
        regDate: data ? data.regDate : this.$now().toISO(),
        uptDate: data ? data.uptDate : this.$now().toISO(),
        registrant: data ? data.registrant : this.$userManager.user.userId,
        registrantName: data ? data.registrantName : undefined,
        modifier: this.$userManager.user.userId,
        modifierName: data ? data.modifierName : undefined,
        // 기본정보
        rentalCompanyId: null,
        accountId: this.printer.accountId || 0,
        printerSerialNo: this.printer.printerSerialNo,
        printerMacAddress: this.printer.printerMacAddress,
        deleteYn: 'N',
        // 재고 관련 설정
        refillTonerChangingLevel: data ? data.refillTonerChangingLevel : 80,
        alertChangedByMonitoring: data ? data.alertChangedByMonitoring : 'N',
        alertTonerCountYn: data ? data.alertTonerCountYn : 'N',
        alertTonerCount: data ? data.alertTonerCount : 0,
        collectedDelayDays: data ? data.collectedDelayDays : 20,
        // 모니터링 프린터 정보
        monitoringPrinterId: this.printer.monitoringPrinterId,
        remark: data ? data.remark : undefined
      }
    },

    async loadDetail() {
      if (!this.monitoringPrinterId) return;
      this.loading = true;
      try {
        const response = await this.$request(PATH.PRINTER.GET)
          .setObject({
            monitoringPrinterId: this.monitoringPrinterId,
          })
          .wait(500)
          .enqueue()
        this.printer = response.data
      } catch (e) {
        //do nothing.
        console.error(e);
      }
      this.loading = false;
    },

    /**
     * 토너 재고 관리 데이터 조회
     */
    async loadTonerRefill() {
      let printerTonerRefillId = 0
      if (
        this.printer.printerModelType !== '1' ||
        (!this.refillHistory && this.printer.refillHistory === 0)
      ) {
        return this.initTonerRefill()
      } else if (this.refillHistory === 0 && this.printer.refillHistory) {
        printerTonerRefillId = this.printer.refillHistory
      } else {
        printerTonerRefillId = this.refillHistory
      }
      const result = await this.$request(PATH.TONER_REFILL.RETRIEVE)
        .setObject({
          printerTonerRefillId,
        })
        .enqueue()
      await this.initTonerRefill(result)
      this.isEnd = false
    },

    /**
     * 토너 재고 이력 조회
     * @returns {Promise<void>}
     */
    async loadTonerRefillHistory() {
      return await this.$request(PATH.TONER_REFILL.RETRIEVE_HISTORY)
        .setObject({
          printerTonerRefillId: this.tonerRefill.printerTonerRefillId,
          ...this.historySearchParam,
        })
        .enqueue()
    },

    async addItems(cb) {
      if (!this.tonerRefill || this.tonerRefill.length === 0) {
        this.isEnd = true
        return
      }
      const res = await this.loadTonerRefillHistory()
      if (res.data.length > 0) {
        if (this.historySearchParam.pageNumber === 1) {
          this.tonerRefillHist = cloneDeep(res.data)
        } else {
          this.tonerRefillHist = this.tonerRefillHist.concat(res.data)
        }
        this.historySearchParam.pageNumber += 1

        cb.loaded()
      } else {
        cb.complete()
        this.isEnd = true
      }
    },

  }
}
</script>

<style scoped>
</style>
