<template>
  <div>
    <!-- 인피니트 리스트 -->
    <vue-infinite-loader
      :items="items"
      :fetch="fetch"
      :identifier="identifier"
      item-key="uniqueKey"
    >
      <template #default="{ item }">
        <printer-item-card :item="item" />
      </template>
    </vue-infinite-loader>

    <!-- 새로고침 버튼 -->
    <refresh-button
      :container="mounted ? $refs.container : undefined"
      @click="onRefresh"
    />
  </div>
</template>

<script>
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import {PATH} from "@/constants";
import OnMainSearchKeywordChanged from "@/event/OnMainSearchKeywordChanged";
import OnMainSearchTriggered from "@/event/OnMainSearchTriggered";
import OnMainFilterTriggered from "@/event/OnMainFilterTriggered";
import Printer from "@/model/printer";
import OnFilterResetListener from "@/framework/listeners/OnFilterResetListener";
import RefreshButton from "@/components/comp/RefreshButton";
import PrinterItemCard from "@/components/items/printer/PrinterItemCard";

export default {
  name: "MainPrinter",
  components: {PrinterItemCard, RefreshButton},
  extends: WindowComponent,
  mixins: [OnMainSearchKeywordChanged, OnMainSearchTriggered, OnMainFilterTriggered, OnFilterResetListener],
  data() {
    return {
      items: [],
      searchOptions: {},
      identifier: 0,
      mounted: false,
      active: true,
    }
  },
  unmounted() {
    this.active = false;
  },
  created() {
    this.initSearchOptions();
    this.$listenerEventBus.$on(this.mpsEvents.ON_MAIN_PRINTER_QUICK_SORT_CHANGED, this.onChangeQuickSort);
  },
  beforeDestroy() {
    this.$listenerEventBus.$off(this.mpsEvents.ON_MAIN_PRINTER_QUICK_SORT_CHANGED, this.onChangeQuickSort);
  },
  mounted() {
    this.mounted = true;
    if (this.historyManager.isForward) { // pushState 한 경우만 초기화
      // 마지막 필터 동작 내용 불러오기
      this.$emit('on-load-save-filter')
      // this.initSearchOptions();
      // this.identifier++;
    } else if (this.historyManager.isBack) {
      // 마지막 필터 동작 내용 불러오기
      this.$emit('on-load-save-filter')
      this.loadAllUpToCurrent()
    }

    this.alreadyLoaded = false;
    this.active = true;
  },
  methods: {
    /**
     * 빠른정렬이 변경되었습니다.(오류순 or 설치순)
     */
    onChangeQuickSort(model) {
      console.log("oiNChangeQuickSort", model)
      if (!this.active) return;
      if (model) {
        this.searchOptions.sortBy = ["regDatetime"]; // 설치순
      } else {
        this.searchOptions.sortBy = ["printerStateCode"]; // 오류순
      }
      this.identifier++;
    },

    async loadAllUpToCurrent() {
      const identifier = this.identifier;
      const response = await this.$request(PATH.PRINTER.NEW_LIST)
        .setObject({
          ...this.searchOptions,
          pageNumber: 1,
          pageSize: this.searchOptions.pageNumber * this.searchOptions.pageSize,
        })
        .wait(500)
        .enqueue();
      if (identifier !== this.identifier) return;
      this.items = response.data.map(e => new Printer(e));
    },

    async fetch(page, pageSize) {
      this.searchOptions.pageNumber = page;
      this.searchOptions.pageSize = pageSize;

      const response = await this.$request(PATH.PRINTER.NEW_LIST)
        .setObject(this.searchOptions)
        .catch()
        .wait(500)
        .enqueue();
      return response.data.map(e => new Printer(e));
    },

    /**
     * 검색키워드와 필터 조건을 초기화 합니다.
     */
    initSearchOptions() {
      this.searchOptions = {sortBy: ["printerStateCode"], sortDesc: [true],};
      this.initFilterState();
    },

    /**
     * 필터 조건을 초기화 합니다.
     */
    initFilterState() {
      /* Do Nothing */
    },

    /**
     * 검색툴바에 엔터 버튼이 눌렸습니다.
     * @param keyword
     */
    onMainSearchTriggered(keyword) {
      this.searchOptions.searchKeyword = keyword;
      this.identifier++;
    },

    /**
     * 검색 조건이 변경되었습니다.
     */
    onMainFilterTriggered(state) {
      // this.searchOptions = {...this.searchOptions, ...state};
      const {searchKeyword, ...otherState} = state
      this.searchOptions = {...this.searchOptions, ...otherState};
      if (!!searchKeyword && searchKeyword !== '')
        this.searchOptions.searchKeyword = searchKeyword;
      this.identifier++;
    },

    /**
     * 필터 조건을 초기화하고 리로드합니다.
     */
    onFilterReset() {
      this.initSearchOptions();
      this.identifier++;
    },

    /**
     * 새로고침 버튼을 클릭하였습니다.
     *
     * 필터조건 변경없이 목록 데이터만 새로 로드합니다.
     */
    onRefresh() {
      this.identifier++;
    },
  }
}
</script>

<style scoped>
>>> .v-expansion-panel-header__icon {
  /*border: 1px solid red !important;*/
  right: 12px !important;
  position: relative !important;
}

>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

>>> .v-expansion-panel-content__wrap {
  padding: 0 12px 0 !important;
}

>>> .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

>>> .v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  max-width: 70px !important;
  padding: 0 16px;
}
</style>

<!--<style>
    .v-list-item {
        padding: 0 !important;
    }
</style>-->
