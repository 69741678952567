<template>
  <div class="ink_wrap">
    <span
      class="ink_text"
      :style="{fontSize: percentComputed >= 100 ? '9px' : '12px'}"
    >{{ percentComputed }}</span>
    <div
      class="ink_box"
      :style="styleObject"
    />
  </div>
</template>

<script>
    export default {
        name: "InkDisplay",
        props: {
            infinite: {
                type: Boolean,
                default: false,
            },
            percent: {
                type: Number,
                default: 0,
            },
            black: {
                type: Boolean,
                default: false,
            },
            cyan: {
                type: Boolean,
                default: false,
            },
            magenta: {
                type: Boolean,
                default: false,
            },
            yellow: {
                type: Boolean,
                default: false,
            },
            isToner: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            percentComputed() {
                let percent = this.percent;
                if (typeof percent !== 'number' || percent < 0 || percent > 100) percent = 0;
                return this.infinite ? 50 : percent;
            },

            inkColorComputed() {
                if (this.black) return "#999";
                if (this.cyan) return "#409ee9";
                if (this.magenta) return "#f555b2";
                if (this.yellow) return "#ffd426";
                return "#999";
            },

            styleObject() {
              if (this.isToner) {
                return {
                  height: '100%',
                  backgroundColor: this.inkColorComputed,
                }
              } else {
                return {
                  height: this.percentComputed + '%',
                  backgroundColor: this.inkColorComputed,
                }
              }
            }
        },
    }
</script>

<style scoped>
    .ink_wrap {
        display: flex;
        flex-direction: column;
        width: 22px;
        height: 22px;
        align-items: center;
        border: 1px solid #ddd;
        border-radius: 3px;
        overflow: hidden;
        box-sizing: border-box;
        margin: 0 auto;
        position: relative;
    }

    .ink_text {
        position: absolute;
        line-height: 22px;
        z-index: 1;
        font-weight: bold;
        font-size: 12px;
        top: -1px
    }

    .ink_box {
        margin-top: auto;
        width: 22px;
        background-color: #999;
        bottom: 0;
    }
</style>