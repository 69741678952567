<template>
  <div class="fill-height">
    <div style="height: 100%;  padding: 0px 15px; overflow-y: auto; background-color: white;">
      <div
        v-for="item in tonerRefillHist"
        :key="item.printerTonerRefillHistoryId"
        class="content-card"
      >
        <div class="content-card-item">
          <div class="item-title">
            {{ '일시' | translate }}
          </div>
          <div class="item-content">
            {{ item.regDate | datetime }}
          </div>
        </div>
        <div class="content-card-item">
          <div class="item-title">
            {{ '' | translate }}
          </div>
          <div class="item-content">
            <table>
              <tr>
                <th>
                  <span class="radi50 bg000">K</span>
                </th>
                <th>
                  <span class="radi50 bg1b75bb">C</span>
                </th>
                <th>
                  <span class="radi50 bgeb008b">M</span>
                </th>
                <th>
                  <span class="radi50 bgfff101">Y</span>
                </th>
              </tr>
            </table>
          </div>
        </div>
        <div class="content-card-item">
          <div class="item-title">
            {{ '변경 전 재고' | translate }}
          </div>
          <div class="item-content">
            <table>
              <tr>
                <th>
                  <span>{{ item.blackTonerPrevCount }}</span>
                </th>
                <th>
                  <span>{{ item.cyanTonerPrevCount }}</span>
                </th>
                <th>
                  <span>{{ item.magentaTonerPrevCount }}</span>
                </th>
                <th>
                  <span>{{ item.yellowTonerPrevCount }}</span>
                </th>
              </tr>
            </table>
          </div>
        </div>
        <div class="content-card-item">
          <div class="item-title">
            {{ '변경 후 재고' | translate }}
          </div>
          <div class="item-content">
            <table>
              <tr>
                <th>
                  <span>{{ item.blackTonerCurCount }}</span>
                </th>
                <th>
                  <span>{{ item.cyanTonerCurCount }}</span>
                </th>
                <th>
                  <span>{{ item.magentaTonerCurCount }}</span>
                </th>
                <th>
                  <span>{{ item.yellowTonerCurCount }}</span>
                </th>
              </tr>
            </table>
          </div>
        </div>
        <div class="content-card-item">
          <div class="item-title">
            {{ '비고' | translate }}
          </div>
          <div class="item-content">
            {{ item.remark }}
          </div>
        </div>
      </div>
      <infinite-loading
        ref="infiniteLoading"
        :identifier="infiniteId"
        @infinite="infiniteHandler"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "PrinterTonerHistoryContent",
  components: {},
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    printer: undefined,
    tonerRefillHist: undefined,
    isEnd: Boolean,
  },
  data() {
    return {
      model: "",
      tabs: [],
      changeId: 0,
      infiniteId: 0,
    }
  },
  watch: {
    expanded(newValue) {
      if (newValue) {
        this.changeId++;
      }
    },
    isEnd() {
      if (!this.isEnd) {
        this.infiniteId++
      }
    },
  },
  created() {
  },
  methods: {
    /**
     * 상위컴포넌트 add-items 메서드를 호출하여
     * 데이터를 조회해온다.
     * @param $state
     */
    infiniteHandler($state) {
      this.$emit('add-items', $state)
    },

  },
}
</script>

<style scoped>
.content-card{
  position: relative;
  font-size: 14px;
  border: 1px solid #eee;
  border-radius: 4px;
  margin: 4px 0;
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.content-card:before{
  content: '';
  border-top: 2px solid #0c98fe;
  border-right: 2px solid #0c98fe;
  position: absolute;
  top: 4px;
  right: 4px;
  height: 12px;
  width: 12px;
  border-top-right-radius: 4px;
}
.content-card-item {
  display: flex;
  flex-direction: row;
  margin: 4px 0;
}
.content-card-item .item-title {
  width: 100px;
  min-width: 100px;
  text-align: left;
  font-weight: bold;
}
.content-card-item .item-content {
  flex: 1;
  padding-left: 8px;
  text-align: left;
  overflow: hidden;
  white-space: wrap;
}
.content-card-item .item-content table {
  width: 100%;
  text-align: center;
}
/*

table tr th {
  color: #333;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  font-size: 14px;
}

table tr td {
  border: 1px solid #ddd;
  color: #333;
}
.toner-refill-info-history {
  margin-top: 4px;
  width: 100%;
  border-collapse: collapse;
}

.toner-refill-info-history tr {
  height: 30px;
  cursor: default;
}

.toner-refill-info-history tr:nth-child(1) th:nth-child(1) {
  width: 55px;
}

.font_size_15 {
  font-size: 15px;
}
*/


</style>
