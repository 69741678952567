<template>
  <div
    id="printer_daily_wrap_con"
    class="overflow-y-auto overflow-x-hidden"
  >
    <my-tool-bar :title="title">
      <template #fixed>
        <component
          :is="filterButtonComponent"
          ref="filterButton"
          :state.sync="keepProps.state"
          @onFilter="onMainFilterTriggered"
          @uniqueModalId="v => keepProps.uniqueModalId = v"
        />
      </template>
    </my-tool-bar>


    <div style="position: relative; top: 56px; height: calc(100% - 56px);">
      <status-options-bar
        :quick-sort.sync="keepProps.quickSort"
        :items="statusOptionsItems"
        @reset="onResetFilter"
      />
    
      <printer-daily-usage-comp
        :printer="printer"
        :search-options="searchOptions? searchOptions : null"
        :identifier="identifier"
      />
    </div>
  </div>
</template>

<script>
    import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
    import {PATH} from "@/constants";
    import VuexProps from "@/framework/vuex/VuexProps";
    import myToolBar from "@/components/app/toolbar/MyToolBar";
    import PrinterDailyUsageComp from "@/components/windows/printer/PrinterDailyUsageComp";
    import FilterButtonMixin from "@/components/app/toolbar/component/filter-button/FilterButtonMixin";
    import {FILTER_BUTTON_TYPE} from "@/components/app/toolbar/component/filter-button/filter-button-types";
    import statusOptionsBar from "@/components/comp/ StatusOptionsBar";
    import KeepProps from "@/views/mixins/KeepProps";
    import now from "@/assets/plugins/mps-common/time/now";


    export default {
        name: "PrinterDailyUsage",
        components: {
          PrinterDailyUsageComp, myToolBar,statusOptionsBar

        },
        extends: WindowComponent,
        mixins: [VuexProps,FilterButtonMixin,KeepProps],
        props: {
           printer:undefined,
        },
        data() {
            return {
              loading: false,
              loadingUpdate: false,
              searchOptions: {
                state: {startDate: this.$now().startOf('day').minus({ month: 3 }), endDate: this.$now().endOf("day")}
              },
              identifier:0,

            }
        },
        computed:{
          /**
           * 현재 라우트에 해당되는 필터 버튼을 반환합니다.
           */
          filterButtonComponent() {
            return this.getFilterButtonComponent(FILTER_BUTTON_TYPE.MAIN_PRINTER_DAILY);

          },

          statusOptionsItems() {
            if(Object.keys(this.keepProps).length === 0) return
            let items = [];
            if (!this.$isEmpty(this.keepProps.filterNameList)) items = items.concat(this.keepProps.filterNameList);
            return items;
          },
        },
        watch: {
            formData: {
                deep: true,
                handler: function () {

                }
            },
            /**
             * 라우터가 변경될 때마다 필터 조건과 검색 키워드를 초기화 시켜줍니다.
             */
            '$route.name'() {
              if (this.doKeepProps() && this.historyManager.wasRoute(this.historyId) && this.historyManager.isForward) {
                this.resetKeepProps(); // 필터 초기화
                this.searchOptions.state = {startDate: this.$now().startOf('day').minus({ month: 3 }), endDate:this.$now().endOf("day")};
              }

              this.historyId = this.historyManager.uniqueId; // 히스토리 고유 아이디 저장
            },
        },
        mounted() {
            this.scrollToTop();
        },
        methods: {

          scrollToTop() {
            window.document.getElementById('printer_daily_wrap_con').scrollTo(0,0)
          },

          getDefaultKeepProps() {
            let state = {};
            let filterNameList = [];

            state = { startDate:  this.$now().startOf('day').minus({ month: 3 }), endDate:  this.$now().endOf("day"),};
            filterNameList =  [{text:  this.$date( this.$now().startOf('day').minus({ month: 3 })) +  " ~ " + this.$date( this.$now().endOf("day"))}, ];

            return {
              state: state,
              filterNameList: filterNameList,
              uniqueModalId: undefined,
            }
          },

          onMainFilterTriggered(data) {
            this.keepProps.state = data.state;
            this.keepProps.filterNameList = data.filterNameList;
            this.searchOptions = {...this.searchOptions, ...data};
            this.identifier++;
          },

          onResetFilter() {
            this.notifyEvent(this.mpsEvents.ON_FILTER_RESET, {uniqueModalId: this.keepProps.uniqueModalId});
            this.initSearchOptions();
            this.resetKeepProps();
            this.identifier++;
          },

          initSearchOptions() {
            this.searchOptions = {}
            this.searchOptions.state = {startDate:this.$now().startOf('day').minus({ month: 3 }), endDate:this.$now().endOf("day")};
          },

        }
    }
</script>

<style scoped>
</style>
