<script>
    import BaseListener from '@/assets/plugins/mps-common/event/base-listener'

    export default {
        name: "OnMainSearchKeywordChanged",
        extends: BaseListener,
        mounted() {
            this.registerListener(this.mpsEvents.ON_MAIN_SEARCH_KEYWORD_CHANGED, this.onSearchKeywordChanged);
        },
        unmounted() {
            this.unregisterListener(this.mpsEvents.ON_MAIN_SEARCH_KEYWORD_CHANGED, this.onSearchKeywordChanged);
        },
        methods: {
            onDrawerOpen() {
                // this will be implemented from a component mixed in.
            }
        },
    }
</script>
